/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

.signer-th {
  width: 260px;
  height: 25px;
  font-weight: 500 !important;
}

.signer-td {
  width: 260px;
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-size: 16px;
  align-items: left;
  vertical-align: middle;
}

.signer-role-th {
  width: 195px;
  height: 25px;
  font-weight: 500 !important;
}

.signer-role-td {
  width: 195px;
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-size: 16px;
  align-items: left;
  vertical-align: middle;
}

.signer-email-th {
  width: 300px;
  height: 25px;
  font-weight: 500 !important;
}

.signer-email-td {
  width: 300px;
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-size: 16px;
  align-items: left;
  vertical-align: middle;
}

.signer-status-th {
  width: 260px;
  height: 25px;
  font-weight: 500 !important;
}

.signer-status-td {
  width: 260px;
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-size: 16px;
  align-items: left;
  vertical-align: middle;
}

.signer-act-th {
  width: 191px;
  height: 25px;
  font-weight: 500 !important;
}

.signer-act-td {
  width: 191px;
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-size: 16px;
  align-items: left;
  vertical-align: middle;
}


.clientName-th {
  width: 302px;
  height: 25px;
  font-weight: 500 !important;
}

.clientName-td {
  width: 302px;
  font-family: "Roboto-Bold", "Roboto Bold", "Roboto", sans-serif;
  font-size: 11px;
  vertical-align: middle !important;
  font-weight: 700;
  color: #036d9b;
}

.account-th {
  width: 200px;
  height: 25px;
  font-weight: 500 !important;
}

.account-td {
  width: 200px;
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-size: 12px;
  vertical-align: middle !important;
}

.status-th {
  width: 250px;
  height: 25px;
  font-weight: 500 !important;
}

.status-td {
  width: 250px;
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-size: 12px;
  vertical-align: middle !important;
}

.agent-th {
  width: 307px;
  height: 25px;
  font-weight: 500 !important;
}

.agent-td {
  width: 307px;
  font-family: "Roboto-Regular", "Roboto", sans-serif;
  font-size: 12px;
  vertical-align: middle !important;
}

.policy-link {
  font-size: 12px;
}

.inp-float__placeholder--float {
  display: none;
}

.fas,
.far {
  padding-right: 5px !important;
}

.ent-dropdown__display,
.ent-dropdown__option {
  height: 35px;
  width: 68px;
  font-size: 13px;
  padding: 5px 20px 5px 8px;
}

.ent-dropdown__selected-option {
  font-size: 13px;
}

.print-page {
  padding: 30px;
}

.print-page__logo {
  border-bottom: 1px solid #dcdee0;
}

.print-page__logo-img {
  width: 138px;
  height: 46px;
  margin-bottom: 25px;
}

.no-print {
  .signer-act-td,
  .signer-act-th {
    visibility: hidden;
  }
}

.print_remove_links {
  a {
    pointer-events: none;
  }
}
